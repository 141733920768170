import React from 'react'

import { SolutionsTable, TableCell, HR } from './style'

const BeneficiosContaPJ = () => {
  return (
    <section className='py-5'>
      <div className='container py-lg-4 py-xl-5'>
        <div className='row'>
          <div className='col-12 col-xl-8 offset-xl-2 px-xl-0'>
            <h2 className='fs-24 lh-30 fs-md-24 fs-lg-32 lh-lg-38 fs-xl-40 lh-xl-50 text-md-center text-grayscale--500 mb-3'>
              Benefícios que você encontra por aqui
            </h2>
            <p className='fs-18 lh-22 text-md-center text-grayscale--500'>
              A conta digital PJ do Inter tem soluções para simplificar o dia a dia da sua empresa.
            </p>
            <SolutionsTable className='mt-4'>
              <TableCell className='d-flex justify-content-between'>
                <div className='d-flex align-items-center'><h3 className='fs-16 lh-20 mb-0 fw-600 text-grayscale--500 py-3'>Serviços</h3></div>
                <div />
                <div className='d-flex justify-content-center align-items-center border-green-top py-3'>
                  <h3 className='fs-16 lh-120 mb-0 text-grayscale--500 fw-600'>Taxa</h3>
                </div>
              </TableCell>
              <HR />
              <TableCell className='d-flex justify-content-between align-items-center'>
                <div><p className='fs-14 lh-17 mb-0 text-grayscale--500'>TEDs e Transferências</p></div>
                <div className='d-flex justify-content-center'><p className='fs-14 lh-17 mb-0 text-grayscale--500'>100/mês</p></div>
                <div className='d-flex justify-content-center border-green'>
                  <p className='fs-16 lh-120 mb-0 text-grayscale--500 fw-600'>Zero</p>
                </div>
              </TableCell>
              <HR />
              <TableCell className='d-flex justify-content-between align-items-center'>
                <div><p className='fs-14 lh-17 mb-0 text-grayscale--500'>Emissão de boletos</p></div>
                <div className='d-flex justify-content-center'><p className='fs-14 lh-17 mb-0 text-grayscale--500'>100/mês</p></div>
                <div className='d-flex justify-content-center border-green'>
                  <p className='fs-16 lh-120 mb-0 text-grayscale--500 fw-600'>Zero</p>
                </div>
              </TableCell>
              <HR />
              <TableCell className='d-flex justify-content-between align-items-center'>
                <div><p className='fs-14 lh-17 mb-0 text-grayscale--500'>Folhas de pagamento</p></div>
                <div className='d-flex justify-content-center'>
                  <p className='fs-14 lh-17 mb-0 text-grayscale--500'>Ilimitadas</p>
                </div>
                <div className='d-flex justify-content-center border-green'>
                  <p className='fs-16 lh-120 mb-0 text-grayscale--500 fw-600'>Zero</p>
                </div>
              </TableCell>
              <HR />
              <TableCell className='d-flex justify-content-between align-items-center'>
                <div><p className='fs-14 lh-17 mb-0 text-grayscale--500'>Cartão Mastercard</p></div>
                <div className='d-flex justify-content-center'>
                  <p className='fs-14 lh-17 mb-0 text-grayscale--500'>Débito</p>
                </div>
                <div className='d-flex justify-content-center border-green'>
                  <p className='fs-16 lh-120 mb-0 text-grayscale--500 fw-600'>Zero</p>
                </div>
              </TableCell>
              <HR />
              <TableCell className='d-flex justify-content-between align-items-center'>
                <div><p className='fs-14 lh-17 mb-0 text-grayscale--500'>Saques</p></div>
                <div className='d-flex justify-content-center'>
                  <p className='fs-14 lh-17 mb-0 text-grayscale--500'>Livres</p>
                </div>
                <div className='d-flex justify-content-center border-green'>
                  <p className='fs-16 lh-120 mb-0 text-grayscale--500 fw-600'>Zero</p>
                </div>
              </TableCell>
              <HR />
              <TableCell className='d-flex justify-content-between align-items-center'>
                <div><p className='fs-14 lh-17 mb-0 text-grayscale--500'>Taxa de manutenção</p></div>
                <div className='d-flex justify-content-center'>
                  <p className='fs-14 lh-17 mb-0 text-grayscale--500'>Anual/mensal</p>
                </div>
                <div className='d-flex justify-content-center border-green'>
                  <p className='fs-16 lh-120 mb-0 text-grayscale--500 fw-600'>Zero</p>
                </div>
              </TableCell>
              <HR />
              <TableCell className='d-flex justify-content-between align-items-center'>
                <div><p className='fs-14 lh-17 mb-0 text-grayscale--500'>Pix</p></div>
                <div className='d-flex justify-content-center'>
                  <p className='fs-14 lh-17 mb-0 text-grayscale--500'>Ilimitado</p>
                </div>
                <div className='d-flex justify-content-center border-green'>
                  <p className='fs-16 lh-120 mb-0 text-grayscale--500 fw-600'>Zero</p>
                </div>
              </TableCell>
              <HR />
              <TableCell className='d-flex justify-content-between align-items-center'>
                <div><h3 className='fs-14 lh-17 mb-0 fw-600 text-green-table'>Custo total dos serviços</h3></div>
                <div />
                <div className='d-flex justify-content-center border-green-bottom fw-600'>
                  <h2 className='fs-16 lh-120 mb-0 text-green fw-600 text-green-table'>ZERO</h2>
                </div>
              </TableCell>
            </SolutionsTable>
          </div>
        </div>
      </div>
    </section>
  )
}

export default BeneficiosContaPJ
